
import { defineComponent } from 'vue';
import { eventos } from '@/fuentes'

export default defineComponent({
  name: 'Eventos',
  data: () => ({
		eventos: eventos
	}),
	mounted() {
		eventos.listar(0)
	},
});
