
<template>

	<div class="uk-overflow-auto contenedor">
		<table class="uk-table uk-table-small uk-table-divider registros">
			<caption>
				<h1>Eventos <div uk-spinner v-if="eventos.estado.listando"></div></h1>
			</caption>
			<thead>
				<tr>
					<th>Identificador</th>
					<th>Fecha</th>
					<th>Ente</th>
					<th>Fiabilidad</th>
					<th>Autenticación</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="e in eventos.estado.listado" :key="`evts-${e.identificador}`">
					<td>{{ e.identificador }}</td>
					<td><span v-if="e.fecha">{{ e.fecha.toLocaleString() }} </span></td>
					<td>{{ e.grupoDeEntes.identificador }}/{{ e.ente.identificador }}</td>
					<td>
						<span v-if="e.fiabilidad >= 0.8">
							<span uk-icon="check" class="uk-text-success"></span> {{ e.fiabilidad }}
						</span>
						<span v-else>
							<span uk-icon="close" class="uk-text-muted"></span> {{ e.fiabilidad }}
						</span>
					</td>
					<td>
						<div v-if="e.verificacion.requerida" class="uk-text-italic">
							<span v-if="(e.verificacion.envio.correoElectronico.enviado || e.verificacion.envio.sms.enviado) && !e.verificacion.validada" class="uk-text-warning">
								<span uk-icon="lock"></span> Requiere autenticación
							</span>
							<span v-else-if="(e.verificacion.envio.correoElectronico.enviado || e.verificacion.envio.sms.enviado) && e.verificacion.validada" class="uk-text-success">
								<span uk-icon="check"></span> Autenticado
							</span>
							<span v-else-if="!e.verificacion.envio.correoElectronico.enviado && !e.verificacion.envio.sms.enviado" class="uk-text-muted">
								<span uk-icon="warning"></span> Requiere verificación propia
							</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<ul class="uk-pagination uk-flex-right uk-margin-small-top uk-margin-small-bottom uk-margin-small-right" uk-margin>
			<li
				:class="{'uk-disabled': eventos.estado.paginacion.indiceActual === 0}"
			>
				<a href="#" @click="eventos.listarPaginaAnterior()"><span uk-pagination-previous></span></a>
			</li>
			<li
				v-for="n in eventos.estado.paginacion.navegacion"
				:key="`pgncn-evts-${n.indice}`"
				:class="{ 'uk-active': n.activa, 'uk-disabled': !n.habilitada }"
			>
				<a href="#" @click="eventos.listar(n.indice)">{{ n.pagina }}</a>
			</li>
			<li
				:class="{'uk-disabled': eventos.estado.paginacion.indiceActual === eventos.estado.paginacion.indiceFinal}"
			>
				<a href="#" @click="eventos.listarPaginaSiguiente()"><span uk-pagination-next></span></a>
			</li>
		</ul>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { eventos } from '@/fuentes'

export default defineComponent({
  name: 'Eventos',
  data: () => ({
		eventos: eventos
	}),
	mounted() {
		eventos.listar(0)
	},
});
</script>
